import React from 'react';
import BountyUserStats from './bountyuserstats';
import BountyUserStatsAdmin from './bountyuserstatsadmin';

const BountyStats = () => {
    const userRole = localStorage.getItem('userRole');
    const isAdmin = userRole === 'admin';

    return isAdmin ? <BountyUserStatsAdmin /> : <BountyUserStats />;
};

export default BountyStats; 