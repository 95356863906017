import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

// Async thunk for fetching bounty status
export const fetchBountyStatus = createAsyncThunk(
  'bounty/fetchStatus',
  async () => {
    try {
      const response = await axios.get('/api/bounty-system');
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for fetching bounty offers
export const fetchBountyOffers = createAsyncThunk(
  'bounty/fetchOffers',
  async () => {
    try {

      const response = await axios.get('/api/bounty-offers');

      return response;
    } catch (error) {

      throw error;
    }
  }
);

const bountySlice = createSlice({
  name: 'bounty',
  initialState: {
    status: {
      is_active: false,
      loading: false,
      error: null
    },
    offers: {
      data: [],
      loading: false,
      error: null
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Status reducers
      .addCase(fetchBountyStatus.pending, (state) => {
        state.status.loading = true;
        state.status.error = null;
      })
      .addCase(fetchBountyStatus.fulfilled, (state, action) => {
        state.status.loading = false;
        state.status.is_active = action.payload?.is_active ?? false;
      })
      .addCase(fetchBountyStatus.rejected, (state, action) => {
        state.status.loading = false;
        state.status.error = action.error.message;
      })
      // Offers reducers
      .addCase(fetchBountyOffers.pending, (state) => {
        state.offers.loading = true;
        state.offers.error = null;
      })
      .addCase(fetchBountyOffers.fulfilled, (state, action) => {
        state.offers.loading = false;
        state.offers.data = action.payload;
      })
      .addCase(fetchBountyOffers.rejected, (state, action) => {
        state.offers.loading = false;
        state.offers.error = action.error.message;
      });
  }
});

export default bountySlice.reducer; 