import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import customAxios from '../../../services/axios';
import { format } from 'date-fns';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Các hằng số
const ITEMS_PER_PAGE = 8;

const BountyUserStats = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState(null);
    const { startDate, endDate } = useSelector((state) => state.dateRange);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);
                const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                
                const response = await customAxios.get('/api/bounty/user-stats/', {
                    params: {
                        start_date: startDate,
                        end_date: endOfDay
                    }
                });

                // Kiểm tra và lấy dữ liệu từ response
                if (response && response.stats) {
                    setStats(response.stats);
                } else {
                    setError('Invalid response format');
                }
            } catch (err) {
                setError(err.response?.data?.error || err.message);
            } finally {
                setLoading(false);
            }
        };

        if (startDate && endDate) {
            fetchStats();
            // Fetch mỗi 5 phút
            const interval = setInterval(fetchStats, 300000);
            return () => clearInterval(interval);
        }
    }, [startDate, endDate]);

    // Cập nhật dữ liệu phân trang khi thay đổi trang hoặc dữ liệu
    useEffect(() => {
        if (stats?.received_bounties?.length > 0) {
            const startIndex = currentPage * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setPaginatedData(stats.received_bounties.slice(startIndex, endIndex));
        } else {
            setPaginatedData([]);
        }
    }, [currentPage, stats]);

    // Xử lý khi thay đổi trang
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-[400px] bg-white rounded-lg">
                <BarLoader color="#3592e8" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500">
                <p>Error loading bounty stats: {error}</p>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg p-4 h-[400px] overflow-auto">
            <div className="mb-4">
                <h3 className="text-lg font-semibold">Your Bounty Stats</h3>
                <p className="text-sm text-gray-500">Total Earned: {stats?.total_bounty_earned || 0} coins</p>
            </div>
            
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="sticky top-0 bg-gray-50">
                        <tr>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Offer Name
                            </th>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Network
                            </th>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Offer Coins
                            </th>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Bounty Amount
                            </th>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Completed By
                            </th>
                            <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {paginatedData.map((bounty, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-2 text-sm text-gray-900 whitespace-nowrap">
                                    {bounty.offer_name}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-900">
                                    {bounty.network}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-900">
                                    {bounty.offer_coins}
                                </td>
                                <td className="px-4 py-2 text-sm font-medium text-green-600">
                                    +{bounty.bounty_amount}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-900">
                                    {bounty.completed_by}
                                </td>
                                <td className="px-4 py-2 text-sm text-gray-500">
                                    {format(new Date(bounty.completed_at), 'dd/MM/yyyy HH:mm')}
                                </td>
                            </tr>
                        ))}
                        {(!paginatedData || paginatedData.length === 0) && (
                            <tr>
                                <td colSpan="6" className="px-4 py-4 text-sm text-center text-gray-500">
                                    No bounties received yet
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* Phân trang */}
                {stats?.received_bounties?.length > 0 && (
                    <ReactPaginate
                        pageCount={Math.ceil(stats.received_bounties.length / ITEMS_PER_PAGE)}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                )}
            </div>
        </div>
    );
};

export default BountyUserStats; 