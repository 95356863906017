/**
 * NetworkTrendChart Component
 * Component hiển thị biểu đồ tròn thể hiện tương quan giữa các network
 * Sử dụng thư viện ApexCharts để vẽ biểu đồ donut
 */

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import NetworkTableData from './networktabledata';
import config from '../../../config';
import { BarLoader } from 'react-spinners';

// Các hằng số cho style
const CHART_CONTAINER_STYLE = 'bg-white rounded-sm justify-center items-center w-full';
const CHART_HEADER_STYLE = 'border-b p-1 border-gray-200 p-3 text-sm font-semibold';
const CHART_TITLE = 'Tương Quan Network';
const CHART_STYLE = 'bg-white rounded-lg justify-center items-center w-full';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Mảng màu sắc cho biểu đồ - Đã được chọn với tông màu đậm
const CHART_COLORS = [
    '#2E3192',   // Xanh dương đậm
    '#1ABC9C',   // Xanh ngọc
    '#E74C3C',   // Đỏ
    '#F39C12',   // Cam
    '#9B59B6',   // Tím
    '#3498DB',   // Xanh dương
    '#E67E22',   // Cam đậm
    '#16A085'    // Xanh lá đậm
];

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading chart data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No chart data available</p>
    </div>
);

/**
 * Cấu hình cho biểu đồ
 * @param {Array} labels - Danh sách tên các network
 * @returns {Object} Cấu hình cho biểu đồ
 */
const getChartOptions = (labels) => ({
    chart: {
        type: 'donut',
        width: '100%'
    },
    labels,
    legend: {
        show: true,
        position: 'right',
        verticalAlign: 'middle',
        offsetY: 0,
        fontSize: '12px',
        markers: {
            width: 12,
            height: 12,
            radius: 12
        }
    },
    colors: CHART_COLORS,
    tooltip: {
        y: {
            formatter: (val) => `$${parseFloat(val).toFixed(2)}`
        }
    }
});

const NetworkTrendChart = () => {
    // Lấy dữ liệu từ Redux store
    const chartData = useSelector(state => state.charts.networkTrendChartData);
    const loading = useSelector(state => state.charts.loading.networkTrendChart);
    const error = useSelector(state => state.charts.error.networkTrendChart);

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!chartData || chartData.length === 0) {
        return <NoDataIndicator />;
    }

    // Tạo cấu hình biểu đồ
    const chartOptions = getChartOptions(chartData.map(entry => entry.name));

    // Tạo dữ liệu series cho biểu đồ
    const chartSeries = chartData.map(entry => entry.data);

    return (
        <div className={CHART_CONTAINER_STYLE}>
            <div className={CHART_HEADER_STYLE}>
                {CHART_TITLE}
            </div>
            <ReactApexChart
                className={CHART_STYLE}
                options={chartOptions}
                series={chartSeries}
                type="donut"
                height={350}
            />
            <NetworkTableData />
        </div>
    );
};

export default NetworkTrendChart;
