/**
 * Overview Component
 * Component chính hiển thị tổng quan về hiệu suất và thống kê của hệ thống
 * Bao gồm các phần:
 * 1. Thống kê tổng quan (Statistics)
 * 2. Xếp hạng hàng ngày (Daily Ranking)
 * 3. Chấm công (Attendance)
 * 4. Phân tích xu hướng (Analytics)
 * 5. Hiệu suất (Performance)
 * 6. Hiệu suất theo site (Site Performance)
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBountyStatus } from '../../services/bountySlice';
import NetworkTrendChart from "./overview/networktrendchart";
import SitesTableData from "./overview/sitestabledata";
import SitesTrendChart from "./overview/sitestrendchart";
import Statistics from "./overview/statistics";
import TopOfferTableData from "./overview/topoffertabledata";
import WorkTrendChart from "./overview/worktrendchart";
import DailyRankChart from "./overview/dailyrankchart";
import AttendanceTable from "./overview/attendance";
import BountyOffersTable from "../generals/overview/bountyofferstable";
import BountyStats from "../generals/overview/bountystats";
import { BarLoader } from 'react-spinners';

const Overview = () => {
    const dispatch = useDispatch();
    const bountyStatus = useSelector((state) => state.bounty.status);

    useEffect(() => {
        dispatch(fetchBountyStatus());
        const interval = setInterval(() => {
            dispatch(fetchBountyStatus());
        }, 300000); // 5 minutes

        return () => clearInterval(interval);
    }, [dispatch]);

    return (
        <div className="mt-20">
            {/* Phần thống kê tổng quan */}
            <section className="statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Statistics
                    </h2>
                    <p className="text-xs">
                        Tóm tắt đánh giá kết quả chung của công việc
                    </p>
                </div>
                <div className="mb-5">
                    <Statistics />
                </div>
            </section>

            {/* Phần xếp hạng hàng ngày */}
            <section className="mb-5 statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Daily Ranking
                    </h2>
                    <p className="text-xs">
                        Kết quả từng nhân viên trong ngày
                    </p>
                </div>
                <div>
                    <DailyRankChart />
                </div>
            </section>
{/* Phần chấm công */}
<section className="statistics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Attendance
                    </h2>
                    <p className="text-xs">
                        Chú ý chấm công hằng ngày
                    </p>
                </div>
                <div className="mb-5">
                    <AttendanceTable />
                </div>
            </section>

            {/* Phần phân tích xu hướng */}
            <section className="charttrend">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Analytics
                    </h2>
                    <p className="text-xs">
                        Thống kê kết quả chung của công việc
                    </p>
                </div>
                <div className="grid p-1 mb-6 charttrend gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                    <WorkTrendChart />
                    <SitesTrendChart />
                </div>
            </section>

            {/* Phần hiệu suất */}
            <section className="charttrend">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Performance
                    </h2>
                    <p className="text-xs">
                        Tóm tắt hiệu suất của công việc
                    </p>
                </div>
                <div className="grid p-1 mb-6 charttrend gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                    <NetworkTrendChart />
                    <TopOfferTableData />
                </div>
            </section>

            {/* Phần Bounty Offers - Chỉ hiển thị khi bounty system active */}
            {bountyStatus.is_active && (
                <section className="mb-5 statistics">
                    <div className="pl-5 mb-4">
                        <h2 className="font-semibold">
                            Bounty System
                        </h2>
                        <p className="text-xs">
                            Thống kê hệ thống bounty và các offer
                        </p>
                    </div>
                    <div className="grid p-1 gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                        {bountyStatus.loading ? (
                            <>
                                <div className="flex items-center justify-center h-[400px] bg-white rounded-lg">
                                    <BarLoader color="#3592e8" />
                                </div>
                                <div className="flex items-center justify-center h-[400px] bg-white rounded-lg">
                                    <BarLoader color="#3592e8" />
                                </div>
                            </>
                        ) : bountyStatus.error ? (
                            <div className="col-span-2 flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500">
                                <p>Error loading bounty status: {bountyStatus.error}</p>
                            </div>
                        ) : (
                            <>
                                <BountyOffersTable />
                                <BountyStats />
                            </>
                        )}
                    </div>
                </section>
            )}

            
            {/* Phần hiệu suất theo site */}
            <section className="site-analytics">
                <div className="pl-5">
                    <h2 className="font-semibold">
                        Site Performance
                    </h2>
                    <p className="text-xs">
                        Tóm tắt hiệu suất của từng site
                    </p>
                </div>
                <div className="grid p-1 mb-6 sitesdata gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-5">
                    <SitesTableData />
                </div>
            </section>
        </div>
    );
};

export default Overview;