import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBountyOffers } from '../../../services/bountySlice';
import { BarLoader } from 'react-spinners';
import axios from '../../../services/axios';
import ReactPaginate from 'react-paginate';

const DEFAULT_IMAGE = "https://easyappicon.com/image/adaptive-icon.svg";

// Các hằng số
const ITEMS_PER_PAGE = 5;

// Các hằng số cho style
const CONTAINER_STYLE = 'max-w-full bg-white rounded-sm';
const HEADER_STYLE = 'flex justify-between p-1 p-3 text-sm font-semibold border-b border-gray-200';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading table data: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No table data available</p>
    </div>
);

const BountyOffersTable = () => {
    const dispatch = useDispatch();
    const { data: offers, loading, error } = useSelector((state) => {
        return state.bounty.offers;
    });
    const [offerImages, setOfferImages] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);

    useEffect(() => {

        dispatch(fetchBountyOffers());
    }, [dispatch]);

    useEffect(() => {
        if (offers && offers.length > 0) {
            // Fetch images for all offers
            const fetchOfferImages = async () => {
                const imagePromises = offers.map(async (offer) => {
                    try {
                        const response = await axios.get(`/api/offer-images/?offer_name=${encodeURIComponent(offer.offer_name)}`);
                        // Kiểm tra nếu response không hợp lệ hoặc không có offer_imagelink
                        if (!response || !response || !response.offer_imagelink) {
                            return {
                                offerName: offer.offer_name,
                                imageLink: DEFAULT_IMAGE
                            };
                        }
                        return {
                            offerName: offer.offer_name,
                            imageLink: response.offer_imagelink
                        };
                    } catch (error) {
                        // Không log lỗi ra console, chỉ trả về hình ảnh mặc định
                        return {
                            offerName: offer.offer_name,
                            imageLink: DEFAULT_IMAGE
                        };
                    }
                });

                try {
                    const results = await Promise.all(imagePromises);
                    const imageMap = results.reduce((acc, curr) => {
                        acc[curr.offerName] = curr.imageLink;
                        return acc;
                    }, {});

                    setOfferImages(imageMap);
                } catch (error) {
                    // Nếu có lỗi khi xử lý kết quả, sử dụng hình ảnh mặc định cho tất cả
                    const defaultImageMap = offers.reduce((acc, offer) => {
                        acc[offer.offer_name] = DEFAULT_IMAGE;
                        return acc;
                    }, {});
                    setOfferImages(defaultImageMap);
                }
            };

            fetchOfferImages();
        }
    }, [offers]);

    // Cập nhật dữ liệu phân trang khi thay đổi trang hoặc dữ liệu
    useEffect(() => {
        if (offers && offers.length > 0) {
            const startIndex = currentPage * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setPaginatedData(offers.slice(startIndex, endIndex));
        }
    }, [currentPage, offers]);

    // Xử lý khi thay đổi trang
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Hiển thị loading state
    if (loading) {
        return <LoadingIndicator />;
    }

    // Hiển thị error state
    if (error) {
        return <ErrorIndicator message={error} />;
    }

    // Hiển thị no data state
    if (!offers || offers.length === 0) {
        return <NoDataIndicator />;
    }

    // Filter active offers only
    const activeOffers = offers.filter(offer => offer.is_active);

    if (activeOffers.length === 0) {
        return <NoDataIndicator />;
    }

    return (
        <div className={CONTAINER_STYLE}>
            <div className={HEADER_STYLE}>
                <span>Bounty Offers</span>
            </div>

            {/* Danh sách offers */}
            <div className="p-2 bg-white rounded-lg overflow-x-none">
                <div className="divide-y divide-gray-200">
                    {paginatedData.map((item, index) => (
                        <li key={index} className="flex items-center justify-between w-full py-2">
                            {/* Thông tin offer bên trái */}
                            <div className="flex items-center w-2/5">
                                <div className="flex items-center justify-center icon min-w-[32px] sm:min-w-[48px]">
                                    <img
                                        src={offerImages[item.offer_name] || DEFAULT_IMAGE}
                                        alt="Offer Image"
                                        className="w-8 h-8 rounded-md sm:w-12 sm:h-12"
                                    />
                                </div>
                                <div className="ml-2 overflow-hidden">
                                    <p className="text-xs font-semibold text-gray-900 truncate">
                                        {item.offer_name}
                                    </p>
                                    <p className="text-xs text-gray-500 truncate">{item.offer_coins || 0} Coins</p>
                                </div>
                            </div>

                            {/* Thông tin chi tiết bên phải */}
                            <div className="w-3/5 pl-2">
                                <div className="grid w-full grid-cols-2 gap-1 sm:flex sm:flex-row sm:gap-2 sm:justify-end">
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 rounded bg-emerald-300 truncate">
                                            {item.network}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-blue-300 rounded truncate">
                                            {item.member_completer_name || '-'}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-orange-300 rounded truncate">
                                            {item.first_completion_time 
                                                ? new Date(item.first_completion_time).toLocaleString() 
                                                : '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>

                {/* Phân trang */}
                <ReactPaginate
                    pageCount={Math.ceil(activeOffers.length / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default BountyOffersTable; 