import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import customAxios from '../../../services/axios';
import { format } from 'date-fns';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

// Các hằng số
const ITEMS_PER_PAGE = 5;

// Các hằng số cho style
const CONTAINER_STYLE = 'max-w-full bg-white rounded-sm';
const HEADER_STYLE = 'flex justify-between p-1 p-3 text-sm font-semibold border-b border-gray-200';
const LOADING_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg';
const ERROR_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-red-500';
const NO_DATA_CONTAINER_STYLE = 'flex items-center justify-center h-[400px] bg-white rounded-lg text-gray-500';

// Component hiển thị loading
const LoadingIndicator = () => (
    <div className={LOADING_CONTAINER_STYLE}>
        <BarLoader color="#3592e8" />
    </div>
);

// Component hiển thị lỗi
const ErrorIndicator = ({ message }) => (
    <div className={ERROR_CONTAINER_STYLE}>
        <p>Error loading bounty stats: {message}</p>
    </div>
);

// Component hiển thị không có dữ liệu
const NoDataIndicator = () => (
    <div className={NO_DATA_CONTAINER_STYLE}>
        <p>No bounty statistics available</p>
    </div>
);

const BountyUserStatsAdmin = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState(null);
    const { startDate, endDate } = useSelector((state) => state.dateRange);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                setLoading(true);
                const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                
                const response = await customAxios.get('/api/bounty/admin-stats/', {
                    params: {
                        start_date: startDate,
                        end_date: endOfDay
                    }
                });
                console.log(response);
                // Kiểm tra và lấy dữ liệu từ response
                if (response && response.stats) {
                    setStats(response.stats);
                } else {
                    setError('Invalid response format');
                }
            } catch (err) {
                setError(err.response?.data?.error || err.message);
            } finally {
                setLoading(false);
            }
        };

        if (startDate && endDate) {
            fetchStats();
            // Fetch mỗi 5 phút
            const interval = setInterval(fetchStats, 300000);
            return () => clearInterval(interval);
        }
    }, [startDate, endDate]);

    // Cập nhật dữ liệu phân trang khi thay đổi trang hoặc dữ liệu
    useEffect(() => {
        if (stats?.length > 0) {
            const startIndex = currentPage * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setPaginatedData(stats.slice(startIndex, endIndex));
        } else {
            setPaginatedData([]);
        }
    }, [currentPage, stats]);

    // Xử lý khi thay đổi trang
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ErrorIndicator message={error} />;
    }

    if (!stats || stats.length === 0) {
        return <NoDataIndicator />;
    }

    return (
        <div className={CONTAINER_STYLE}>
            <div className={HEADER_STYLE}>
                <span>Bounty System Statistics</span>
            </div>
            
            <div className="p-2 bg-white rounded-lg overflow-x-none">
                <div className="divide-y divide-gray-200">
                    {paginatedData.map((user, index) => (
                        <li key={index} className="flex items-center justify-between w-full py-2">
                            {/* Thông tin user bên trái */}
                            <div className="flex items-center w-2/5">
                                <div className="ml-2 overflow-hidden">
                                    <p className="text-xs font-semibold text-gray-900 truncate">
                                        {user.username}
                                    </p>
                                    <p className="text-xs text-gray-500 truncate">
                                        {user.first_completions_count} First Completions
                                    </p>
                                </div>
                            </div>

                            {/* Thông tin chi tiết bên phải */}
                            <div className="w-3/5 pl-2">
                                <div className="grid w-full grid-cols-2 gap-1 sm:flex sm:flex-row sm:gap-2 sm:justify-end">
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-blue-300 rounded truncate">
                                            {user.total_offer_coins} Coins
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-center min-w-[45px] sm:min-w-[100px]">
                                        <span className="w-full px-1 py-0.5 sm:px-2 sm:py-1 text-[10px] sm:text-xs text-center text-gray-900 bg-green-300 rounded truncate">
                                            +{user.total_bounty_earned}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>

                {/* Phân trang */}
                {stats?.length > 0 && (
                    <ReactPaginate
                        pageCount={Math.ceil(stats.length / ITEMS_PER_PAGE)}
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                )}
            </div>
        </div>
    );
};

export default BountyUserStatsAdmin; 