import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { increaseTotalAccount, updateRevenueFromTransaction } from '../../services/statisticsSlice';
import config from '../../config';

function Timeline() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    const [showAllTransactions, setShowAllTransactions] = useState(true);
    const [platform, setPlatform] = useState('SaviartMedia');
    const [wsUrl, setWsUrl] = useState(`${config.MAIN_WEBSOCKET_URL}${config.WEBSOCKET_ENDPOINTS.TIMELINE}`);
    const wsRef = useRef(null);
    const currentWsUrl = useRef(wsUrl); // Ref này sẽ luôn phản ánh đường dẫn WS hiện tại để kết nối
    const [tick, setTick] = useState(0);
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        function connect() {
            const ws = new WebSocket(currentWsUrl.current);
            wsRef.current = ws;

            ws.onopen = () => console.log('WebSocket Connected');

            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                handleWebSocketMessage(message);
            };

            ws.onerror = (error) => console.error('WebSocket Error:', error);

            ws.onclose = () => {
                console.log('WebSocket Disconnected. Checking for reconnection...');
                // Chỉ kết nối lại nếu URL hiện tại phù hợp với lựa chọn platform
                if (currentWsUrl.current === wsUrl) {
                    console.log('Reconnecting...');
                    setTimeout(connect, 5000);
                }
            };
        }

        // Đặt lại và kết nối lại khi wsUrl thay đổi
        currentWsUrl.current = wsUrl;
        connect();

        // Bộ đếm tick
        const interval = setInterval(() => {
            setTick(prev => prev + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [wsUrl]); // Theo dõi sự thay đổi của wsUrl

    const handlePlatformChange = (newPlatform) => {
        setPlatform(newPlatform);
        const newUrl = newPlatform === config.SITES.MAIN.NAME 
            ? `${config.MAIN_WEBSOCKET_URL}${config.WEBSOCKET_ENDPOINTS.TIMELINE}`
            : `${config.SECONDARY_WEBSOCKET_URL}${config.WEBSOCKET_ENDPOINTS.TIMELINE}`;
        setWsUrl(newUrl);
    };
    const handleWebSocketMessage = (message) => {
        switch (message.type) {
            case 'TIMELINE':
                setData(message.timeline);
                break;
            case 'TIMELINE_UPDATE':
                // Ensure the new timeline update is correctly structured
                const newTimelineUpdate = {
                    history_timecreated: message.history_timecreated,
                    history_username: message.member_name, // Adjust to match the field name from the server
                    history_offername: message.history_offername,
                    history_network: message.history_network,
                    history_coins: message.history_coins,
                    history_site: message.history_site,
                    id: message.history_id
                };
                setData(prevData => [newTimelineUpdate, ...prevData]);
                break;
            case 'NEW_MEMBER_REGISTERED':
                if (userRole === 'admin' || checkMemberMatch(message.newmember)) {
                    dispatch(increaseTotalAccount());
                }
                break;
            case 'NEW_TRANSACTION':
                if (userRole === 'admin' || checkMemberMatch(message.member)) {
                    if (message.coins) {
                        dispatch(updateRevenueFromTransaction(message.coins));
                    }
                }
                break;
            case 'LOGIN_ATTEMPT':
                const timecreated = new Date().toISOString();
                const username = message.username || 'Unknown';
               
                break;
            default:

        }
    };
    const capitalizeFirstLetter = (string) => {
        return string
            .split(' ') // Tách chuỗi thành mảng các từ
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Chuyển ký tự đầu tiên thành chữ hoa và còn lại thành chữ thường
            .join(' '); // Gộp lại thành một chuỗi
    };
    const checkMemberMatch = (memberName) => {
        const memname = localStorage.getItem('memname');
        return memname && memberName && memberName.toLowerCase().startsWith(memname.toLowerCase());
    };

    const filteredData = showAllTransactions ? data : data.filter(history => checkMemberMatch(history.history_username));

    const toggleTimeLine = () => setIsOpen(!isOpen);

    const isRecent = (timeCreated) => moment().diff(moment(timeCreated), 'seconds') < 30;

    const recentCount = data.filter(history => isRecent(history.history_timecreated)).length;

    const timelineContainerRef = useRef(null);

    const scrollToTop = () => {
        timelineContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleShowAllTransactionsChange = () => setShowAllTransactions(!showAllTransactions);

    const extractMiddlePart = (site) => {
        const parts = site.split('.');
        return parts.length === 3 ? parts[1] : site;
    };

    return (
        <div className={`fixed top-0 right-0 md:w-5/12 w-full h-screen transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-[calc(100%-2rem)]'}`}>
            <div onClick={toggleTimeLine} className="fixed cursor-pointer rounded-s-2xl top-16 left-0 w-[2rem] h-[5rem] bg-blue-500 text-white shadow-md flex justify-center items-center text-exs hover:scale-105 transition duration-300">
                <div className="transform -rotate-90 select-none">TIMELINE</div>
                {recentCount > 0 && (
                    <div className="absolute top-0 left-0 flex items-center justify-center w-4 h-4 mt-1 mr-1 text-white bg-red-500 rounded-full text-exs">
                        {recentCount}
                    </div>
                )}
            </div>
            <div className='h-full mt-16 ml-8 overflow-auto bg-white rounded-md shadow-md cs' ref={timelineContainerRef}>
                <div className='flex items-center p-2 ml-3'>
                    <label className="inline-flex items-center cursor-pointer me-5">
                        <input type="checkbox" className="sr-only peer" checked={showAllTransactions} onChange={handleShowAllTransactionsChange} />
                        <div className={`relative w-8 h-4 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 ${showAllTransactions ? 'bg-blue-500' : 'bg-gray-200'} peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600`}></div>
                        <span className="text-xs font-semibold text-gray-900 ms-3 dark:text-gray-300">{showAllTransactions ? 'All Transaction' : 'Only Me'}</span>
                    </label>

                    <label className="inline-flex items-center cursor-pointer">
                        <input 
                            type="checkbox" 
                            className="sr-only peer" 
                            checked={platform === config.SITES.SECONDARY.NAME}
                            onChange={(e) => handlePlatformChange(e.target.checked ? config.SITES.SECONDARY.NAME : config.SITES.MAIN.NAME)}
                        />
                        <div className={`relative w-8 h-4 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 ${platform === config.SITES.SECONDARY.NAME ? 'bg-blue-500' : 'bg-gray-200'} peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600`}></div>
                        <span className="text-xs font-semibold text-gray-900 ms-3 dark:text-gray-300">{platform}</span>
                    </label>
                </div>

                <ol className="relative ml-5 border-gray-200 border-s dark:border-gray-700">
                    {filteredData.map((history, index) => (
                        <li className="mb-1 ms-5" key={history.id || index}> {/* Use a unique property like `history.id` or fallback to `index` */}
                            <div className={`absolute w-3 h-3  rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900  ${isRecent(history.history_timecreated) ? 'bg-red-500' : 'bg-gray-200'} dark:bg-gray-700`}></div>
                            <time className="mb-1 font-normal text-exs leading-nonetext-gray-400 dark:text-gray-500">
                                {moment(history.history_timecreated).fromNow()}
                            </time>
                            <div>
                                <span className={`text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}> {capitalizeFirstLetter(history.history_username)}</span>

                                <span className="mb-1 font-normal text-gray-500 text-exs dark:text-gray-400"> đã hoàn thành </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_offername} </span>
                                <span className="mb-1 font-normal text-gray-500 text-exs dark:text-gray-400">network </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_network} </span>
                                <span className="mb-1 font-normal text-gray-500 text-exs dark:text-gray-400">nhận được </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{history.history_coins} Coins </span>
                                <span className="mb-1 font-normal text-gray-500 text-exs dark:text-gray-400">lúc </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}> {moment(history.history_timecreated).format('DD-MM-YYYY HH:mm:ss')} </span>
                                <span className="mb-1 font-normal text-gray-500 text-exs dark:text-gray-400">tại site </span>
                                <span className={`mb-1 text-xs font-semibold  ${isRecent(history.history_timecreated) ? 'text-red-500' : 'text-blue-800'} dark:text-gray-400`}>{extractMiddlePart(history.history_site)}</span>
                            </div>
                        </li>
                    ))}
                </ol>
                <button onClick={scrollToTop} className="fixed px-5 py-1 text-sm font-semibold text-black bg-green-400 rounded-lg bottom-5 right-5 hover:bg-green-700 mt-100">
                    GO BACK TOP
                </button>
            </div>
        </div>
    );
}

export default Timeline;
