import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import config from '../../config';

function Chatbox() {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const ws = useRef(null);
    const currentUser = localStorage.getItem('username');
    const messagesEndRef = useRef(null);

    const connectWebSocket = () => {
        ws.current = new WebSocket(`${config.MAIN_WEBSOCKET_URL}${config.WEBSOCKET_ENDPOINTS.CHAT}`);
        ws.current.onopen = () => console.log('WebSocket Connected');
        ws.current.onerror = (error) => console.log('WebSocket Error:', error);
        ws.current.onclose = () => {
            console.log('WebSocket Disconnected. Attempting to reconnect...');
            setTimeout(connectWebSocket, 5000);
        };
        ws.current.onmessage = e => {
            const data = JSON.parse(e.data);
            if (Array.isArray(data)) {
                setMessages(data);
            } else {
                setMessages(prevMessages => [...prevMessages, data]);
            }
        };
    };

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const toggleChat = () => setIsOpen(!isOpen);

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
            e.preventDefault();
        }
    };

    const handleSendMessage = () => {
        if (message && ws.current) {
            const username = localStorage.getItem('username') || 'Anonymous';
            const data = { username, message };
            ws.current.send(JSON.stringify(data));
            setMessage("");
        }
    };

    return (
        <>
            {/* Chat Toggle Button */}
            <div 
                onClick={toggleChat} 
                className={`fixed cursor-pointer rounded-full bottom-4 right-4 w-[2.5rem] h-[2.5rem] bg-gradient-to-r from-blue-500 to-blue-600 shadow-lg flex justify-center items-center text-exs scale-105 transition-all duration-300 hover:scale-110 hover:shadow-xl hover:from-blue-600 hover:to-blue-700 z-50 ${isOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}
            >
                <div className="select-none">
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 9H17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 13H13" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>

            {/* Chat Container */}
            <div className={`fixed bottom-10 right-0 md:w-3/12 w-full h-1/2 transition-all duration-300 ease-in-out ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-[calc(100%-2rem)] opacity-90'}`}>
                <div className='message-container h-full rounded-lg bg-white ml-8 shadow-xl mt-16 overflow-hidden border border-gray-100'>
                    <div className="flex-1 p-1 sm:p-3 justify-between flex flex-col h-full">
                        {/* Header */}
                        <div className="flex sm:items-center justify-between py-2 border-b border-gray-100 w-full bg-white relative">
                            <div className="text-xs font-semibold text-gray-900 flex items-center">
                                <span className="w-1.5 h-1.5 bg-green-500 rounded-full mr-2"></span>
                                NOTE
                            </div>
                            <button 
                                onClick={toggleChat}
                                className="p-0.5 hover:bg-gray-100 rounded-full transition-colors duration-200"
                            >
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="#666666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>

                        {/* Messages Area */}
                        <div id="messages" className="flex flex-col space-y-3 p-3 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-blue-300 scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                            {messages.map((msg, index) => (
                                <div key={index} className={`flex w-full ${msg.username === currentUser ? 'justify-end' : 'justify-start'}`}>
                                    {msg.username !== currentUser && (
                                        <div className="flex-shrink-0 mr-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.17157 3.17157C2 4.34315 2 6.22876 2 10V14C2 17.7712 2 19.6569 3.17157 20.8284C3.82496 21.4818 4.70043 21.7708 6.00121 21.8986C6.066 19.184 8.72694 17 12 17C15.2731 17 17.934 19.184 17.9988 21.8986C19.2996 21.7708 20.175 21.4818 20.8284 20.8284C22 19.6569 22 17.7712 22 14V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C6.22876 2 4.34315 2 3.17157 3.17157ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" fill="#7E869E" fillOpacity="0.25" />
                                                <path d="M17.9407 21.2989C17.7395 20.1146 17.0355 19.0298 15.9565 18.2411C14.8775 17.4524 13.495 17.0122 12.0599 17.0002C10.6247 16.9883 9.23198 17.4055 8.13433 18.176C7.03668 18.9466 6.30685 20.0196 6.07726 21.2002" stroke="#222222" strokeWidth="1.2" />
                                                <circle cx="12" cy="10" r="3" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
                                                <rect x="2.6" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="#222222" strokeWidth="1.2" />
                                            </svg>
                                        </div>
                                    )}
                                    <div className={`flex flex-col space-y-1 text-xs w-full max-w-[85%] mx-2 ${msg.username === currentUser ? 'items-end' : 'items-start'}`}>
                                        <div className="group w-full">
                                            <span className={`px-3 py-2 rounded-2xl inline-block transition-all duration-200 w-full break-words whitespace-pre-wrap ${
                                                msg.username === currentUser 
                                                    ? 'rounded-br-sm bg-blue-500 text-white' 
                                                    : 'rounded-bl-sm bg-gray-100 text-gray-800'
                                            }`}>
                                                <div className={`text-xs font-semibold mb-1 ${msg.username === currentUser ? 'text-blue-100' : 'text-blue-600'}`}>{msg.username}</div>
                                                {msg.message}
                                            </span>
                                            <div className="text-exss text-gray-400 mt-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                                {moment(msg.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                                            </div>
                                        </div>
                                    </div>
                                    {msg.username === currentUser && (
                                        <div className="flex-shrink-0 ml-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.17157 3.17157C2 4.34315 2 6.22876 2 10V14C2 17.7712 2 19.6569 3.17157 20.8284C3.82496 21.4818 4.70043 21.7708 6.00121 21.8986C6.066 19.184 8.72694 17 12 17C15.2731 17 17.934 19.184 17.9988 21.8986C19.2996 21.7708 20.175 21.4818 20.8284 20.8284C22 19.6569 22 17.7712 22 14V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C6.22876 2 4.34315 2 3.17157 3.17157ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" fill="#7E869E" fillOpacity="0.25" />
                                                <path d="M17.9407 21.2989C17.7395 20.1146 17.0355 19.0298 15.9565 18.2411C14.8775 17.4524 13.495 17.0122 12.0599 17.0002C10.6247 16.9883 9.23198 17.4055 8.13433 18.176C7.03668 18.9466 6.30685 20.0196 6.07726 21.2002" stroke="#222222" strokeWidth="1.2" />
                                                <circle cx="12" cy="10" r="3" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
                                                <rect x="2.6" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="#222222" strokeWidth="1.2" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>

                        {/* Input Area */}
                        <div className="border-t-2 border-gray-100 px-2 pt-3 mb-2 sm:mb-0 bg-white">
                            <div className="flex gap-2 justify-center items-center mb-2">
                                <textarea
                                    value={message}
                                    onChange={handleMessageChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Write your message!"
                                    className="w-full px-3 py-2 text-xs h-10 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent text-gray-600 placeholder-gray-400 bg-gray-50 rounded-full resize-none transition-all duration-200"
                                ></textarea>
                                <button 
                                    type="button" 
                                    onClick={handleSendMessage} 
                                    className="inline-flex text-xs items-center justify-center rounded-full px-4 py-2 transition duration-300 ease-in-out text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 shadow-md hover:shadow-lg"
                                >
                                    <span>Send</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 ml-2 transform rotate-90">
                                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chatbox;
